const newRoutes = [
  {
    path: "/console",
    name: "console.home",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    alias: "/console/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "console.home" */ "../pages/console/Dashboard/Dashboard"
      ),
  },
  {
    path: "/console/notices",
    name: "console.notices",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.notices" */ "../pages/console/Notices/Notices"
      ),
  },
  {
    path: "/console/notices/:noticeResourceId",
    name: "console.notices.show",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.notices.show" */ "../pages/console/ShowNotice/ShowNotice"
      ),
  },
  {
    path: "/console/onboarding",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.onboard" */ "../pages/console/OnBoarding/OnBoarding"
      ),
    children: [
      {
        path: "",
        name: "console.onboarding",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.intro" */ "../pages/console/OnBoarding/OnBoardingIntro/OnBoardingIntro"
          ),
      },
      {
        path: "profile",
        name: "console.onboarding.profile",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.profile" */ "../pages/console/OnBoarding/OnBoardingProfile/OnBoardingProfile"
          ),
      },
      {
        path: "interest",
        name: "console.onboarding.interest",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.interest" */ "../pages/console/OnBoarding/OnBoardingInterest/OnBoardingInterest"
          ),
      },
      {
        path: "purpose",
        name: "console.onboarding.purpose",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.purpose" */ "../pages/console/OnBoarding/OnBoardingPurpose/OnBoardingPurpose"
          ),
      },
      {
        path: "channel",
        name: "console.onboarding.channel",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.channel" */ "../pages/console/OnBoarding/OnBoardingChannel/OnBoardingChannel"
          ),
      },
      {
        path: "complete",
        name: "console.onboarding.complete",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.complete" */ "../pages/console/OnBoarding/OnBoardingComplete/OnBoardingComplete"
          ),
      },
      {
        path: "review",
        name: "console.onboarding.review",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.review" */ "../pages/console/OnBoarding/OnBoardingReview/OnBoardingReview"
          ),
      },
      {
        path: "approved",
        name: "console.onboarding.approved",
        component: () =>
          import(
            /* webpackChunkName: "console.onboarding.approved" */ "../pages/console/OnBoarding/OnBoardingApproved/OnBoardingApproved"
          ),
      },
    ],
  },
  {
    path: "/console/brand",
    name: "console.brand.widgets",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    alias: "/console/brand/widgets",
    component: () =>
      import(
        /* webpackChunkName: "console.ent.brand" */ "../pages/console/EntBrand/EntBrand"
      ),
  },
  {
    path: "/console/brand/profile",
    name: "console.brand.profile",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.brand.profile" */ "../pages/console/EntBrand/EntBrand"
      ),
  },
  {
    path: "/console/brand/design",
    name: "console.brand.design",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.brand.design" */ "../pages/console/EntBrand/EntBrand"
      ),
  },
  {
    path: "/console/brand/navigations",
    name: "console.brand.navigations",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.brand.menu" */ "../pages/console/EntBrand/EntBrand"
      ),
  },
  {
    path: "/console/brand/insight",
    name: "console.brand.insight",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.brand.insight" */ "../pages/console/EntBrand/EntBrand"
      ),
  },
  {
    path: "/console/themes",
    name: "console.themes",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.themes" */ "../pages/console/EntClubCreate/ClubCreate"
      ),
  },
  {
    path: "/console/clubs",
    name: "console.clubs",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    // props: true,
    props: (route) => ({ query: route.query.q }),
    component: () =>
      import(
        /* webpackChunkName: "console.clubs" */ "../pages/console/EntClubs/Clubs"
      ),
  },
  {
    path: "/console/clubs/:clubResourceId",
    name: "console.clubs.show",
    props: true,
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.clubs.show" */ "../pages/console/EntClubShow/EntClubShow"
      ),
  },
  {
    path: "/console/clubs/:clubResourceId/articles/create",
    name: "console.clubs.articles.create",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.clubs.articles.create" */ "../pages/console/ArticleForm/ArticleForm"
      ),
  },
  {
    path: "/console/clubs/:clubResourceId/articles/:articleResourceId",
    name: "console.clubs.articles.edit",
    props: true,
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.clubs.articles.edit" */ "../pages/console/ArticleForm/ArticleForm"
      ),
  },
  {
    path: "/console/memberships",
    name: "console.memberships",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.memberships" */ "../pages/console/Membership/Memberships"
      ),
  },
  {
    path: "/console/channels",
    name: "console.channels",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.channels" */ "../pages/console/Channels/Channels"
      ),
  },
  {
    path: "/console/channels/callback/:provider",
    name: "console.channels.callback",
    props: true,
    meta: { requiresAuth: true, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.channels.callback" */ "../pages/console/Channels/Callback"
      ),
  },
  {
    path: "/console/members",
    name: "console.members",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.members" */ "../pages/console/Members/Members"
      ),
  },
  {
    path: "/console/articles",
    name: "console.articles",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.articles" */ "../pages/console/Articles/Articles"
      ),
  },
  {
    path: "/console/articles/create",
    name: "console.articles.create",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.articles.create" */ "../pages/console/ArticleForm/ArticleForm"
      ),
  },
  {
    path: "/console/articles/:articleResourceId",
    name: "console.articles.edit",
    props: true,
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.articles.edit" */ "../pages/console/ArticleForm/ArticleForm"
      ),
  },
  {
    path: "/console/earning",
    name: "console.earning",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.earning" */ "../pages/console/Earning/Earning.vue"
      ),
  },
  {
    path: "/console/account",
    name: "console.account",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.account" */ "../pages/console/Account/Account"
      ),
  },
  {
    path: "/console/contents",
    name: "console.contents",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: (route) => ({ query: route.query.q }),
    component: () =>
      import(
        /* webpackChunkName: "console.contents" */ "../pages/console/ContentsList/ContentsList"
      ),
  },
  {
    path: "/console/contents/create",
    name: "console.contents.create",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.contents.create" */ "../pages/console/ContentCreate/ContentCreate"
      ),
  },
  {
    path: "/console/contents/:contentResourceId",
    name: "console.contents.show",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.contents.show" */ "../pages/console/ContentShow/ContentShow"
      ),
  },
  {
    path: "/console/contents/:contentResourceId/edit",
    name: "console.contents.edit",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.contents.edit" */ "../pages/console/ContentEdit/ContentEdit"
      ),
  },
  {
    path: "/console/ent/contents",
    name: "console.ent.contents",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.ent.contents" */ "../pages/console/EntContents/EntContents"
      ),
  },
  {
    path: "/console/ent/contents/create",
    name: "console.ent.contents.create",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.ent.contents.create" */ "../pages/console/EntContentsCreate/EntContentsCreate"
      ),
  },
  {
    path: "/console/ent/contents/:contentResourceId/edit",
    name: "console.ent.contents.edit",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.ent.contents.edit" */ "../pages/console/EntContentsEdit/EntContentsEdit"
      ),
  },
  {
    path: "/console/settlement",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.settlement" */ "../pages/console/Settlement/Settlement"
      ),
    children: [
      {
        path: "",
        name: "console.settlement",
        component: () =>
          import(
            /* webpackChunkName: "console.settlement.home" */ "../pages/console/Settlement/TabSales/TabSales"
          ),
      },
      {
        path: "sales",
        name: "console.settlement.sales",

        component: () =>
          import(
            /* webpackChunkName: "console.settlement.sales" */ "../pages/console/Settlement/TabSales/TabSales"
          ),
      },
      {
        path: "calculate",
        name: "console.settlement.calculate",

        component: () =>
          import(
            /* webpackChunkName: "console.settlement.calculate" */ "../pages/console/Settlement/TabCalculate/TabCalculate"
          ),
      },
      {
        path: "point",
        name: "console.settlement.point",

        component: () =>
          import(
            /* webpackChunkName: "console.settlement.point" */ "../pages/console/Settlement/TabPoint/TabPoint"
          ),
      },
      {
        path: "info",
        name: "console.settlement.info",

        component: () =>
          import(
            /* webpackChunkName: "console.settlement.info" */ "../pages/console/Settlement/TabInfo/TabInfo"
          ),
      },
    ],
  },
  {
    path: "/console/settlement/calculate/:settlementResourceId",
    name: "console.settlement.calculate.show",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.settlement.calculate.show" */ "../pages/console/Settlement/CalculateDetail/CalculateDetail"
      ),
  },
  {
    path: "/console/phone",
    name: "console.phone",
    meta: {
      requiresAuth: true,
      layout: "FullLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.phone" */ "../pages/console/Phone/Phone.vue"
      ),
  },
  {
    path: "/console/open_hour_info",
    name: "console.openHourInfo",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.openHourInfo" */ "../pages/console/OpenHourInfo/OpenHourInfo.vue"
      ),
  },
  {
    path: "/console/open_hour_schedule",
    name: "console.openHourSchedule",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.openHourSchedule" */ "../pages/console/OpenHourSchedule/OpenHourSchedule.vue"
      ),
  },
  {
    path: "/console/open_hour_reservation",
    name: "console.openHourReservation",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.openHourReservation" */ "../pages/console/OpenHourReservation/OpenHourReservation.vue"
      ),
  },
  {
    path: "/console/open_hour_review",
    name: "console.openHourReview",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.openHourReview" */ "../pages/console/OpenHourReview/OpenHourReview.vue"
      ),
  },
  {
    path: "/console/open_hour_chats",
    name: "console.openHourChats",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.chats" */ "../pages/console/Chats/Chats.vue"
      ),
  },
  {
    path: "/console/lectures",
    name: "console.lectures",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.lectures" */ "../pages/console/Lectures/Lectures.vue"
      ),
  },
  {
    path: "/console/lectures/:lectureResourceId",
    name: "console.lectures.detail",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.lectures.detail" */ "../pages/console/LectureEdit/LectureEdit.vue"
      ),
  },
  {
    path: "/console/lectures/:lectureResourceId/reservations",
    name: "console.lectures.reservations",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.lectures.reservations" */ "../pages/console/LectureReservations/LectureReservations.vue"
      ),
  },
  {
    path: "/console/ent/themes",
    name: "console.ent.themes",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.ent.themes" */ "../pages/console/ClubCreate/ClubCreate"
      ),
  },
  {
    path: "/console/ent/clubs",
    name: "console.ent.clubs",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.ent.clubs" */ "../pages/console/Clubs/Clubs"
      ),
  },
  {
    path: "/console/ent/clubs/:clubResourceId/edit",
    name: "console.ent.clubs.edit",
    props: true,
    meta: { requiresAuth: true, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.ent.clubs.edit" */ "../pages/console/ClubEdit/ClubEdit"
      ),
  },
  {
    path: "/console/ent/clubs/:clubResourceId",
    name: "console.ent.clubs.show",
    props: true,
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.ent.clubs.show" */ "../pages/console/ClubShow/ClubShow"
      ),
  },
  {
    path: "/console/ent/meetings",
    name: "console.ent.meetings",
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "console.ent.clubs" */ "../pages/console/EntMeetings/EntMeetings"
      ),
  },
  {
    path: "/console/polls",
    name: "console.polls",
    props: true,
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.polls" */ "../pages/console/Polls/Polls.vue"
      ),
  },
  {
    path: "/console/polls/:pollResourceId/edit",
    name: "console.polls.edit",
    props: true,
    meta: {
      requiresAuth: true,
      layout: "NewLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "console.polls.edit" */ "../pages/console/PollEdit/PollEdit.vue"
      ),
  },
  {
    path: "/console/labs",
    name: "console.labs",
    meta: { requiresAuth: true, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.Labs" */ "../pages/console/Labs/Labs"
      ),
  },
  {
    path: "/console/labs/sidemodal",
    name: "console.labs.sideModal",
    meta: { requiresAuth: true, layout: "ConsoleBasicLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.Labs.sideModal" */ "../pages/console/Labs/LabsSideModal"
      ),
  },
  {
    path: "/console/labs/sticky",
    name: "console.labs.sticky",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.labs.sticky" */ "../pages/console/Labs/LabsSticky"
      ),
  },
  {
    path: "/console/labs/calendar",
    name: "console.labs.calendar",
    meta: { requiresAuth: true, layout: "NewLayout" },
    component: () =>
      import(
        /* webpackChunkName: "console.labs.calendar" */ "../pages/console/Labs/LabsCalendar.vue"
      ),
  },
];

export default newRoutes;
