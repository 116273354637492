import { useRouter } from "vue-router";

export function useMoveRoute() {
  const router = useRouter();

  const moveRoute = (params: {
    routeName?: string;
    path?: string;
    params?: any;
  }) => {
    if (params.params) {
      router.push({ name: params.routeName, params: params.params });
    }

    if (params.routeName) {
      router.push({ name: params.routeName });
      return;
    }

    if (params.path) {
      router.push({ path: params.path });
      return;
    }

    console.warn("moveRoute: 'routeName' or 'path' is required.");
  };

  const moveRouteQueryParameter = (params: {
    routeName?: string;
    path?: string;
    query?: Record<string, any>;
  }) => {
    if (params.routeName) {
      // Case 1: Navigate using named route
      router.push({ name: params.routeName, query: params.query });
      return;
    }
    if (params.path) {
      // Case 2: Navigate using path
      router.push({ path: params.path, query: params.query });
      return;
    }
    console.warn(
      "moveRouteQueryParameter: Either 'routeName' or 'path' must be provided."
    );
  };

  return { moveRoute, moveRouteQueryParameter };
}
